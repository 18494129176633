import React from 'react'
import { graphql } from 'gatsby'
import Layout from '../components/Layout'
import SEO from '../components/seo'
import SampleForm from '../components/Form'
import swish from '../img/swish.png'
import swishw from '../img/swish-white.png'
import checkmarkw from '../img/checkmark-white.png'
import checkmark from '../img/checkmark.png'
import moneydialogue from '../img/money-dialogue.svg'
// import whitebranch from '../img/large-branch-white.png'

class OurProcessPage extends React.Component {
  render() {
    const siteTitle = 'Our Process'
    const siteDescription = this.props.data.site.siteMetadata.description

    return (
      <Layout location={this.props.location} title={siteTitle}>
        <SEO title={siteTitle} description={siteDescription} />
        <div className="atf-process">
          <h1 className="atf-h text-center text-white">
            <strong>Our Process</strong>
          </h1>
        </div>
        <div className="our-service">
          <div className="container">
            <div className="col-md-12">
              <div className="bg text-center">
                <img alt="checkmark" src={checkmark} />
              </div>
              <h1 className="section-h text-center">
                <strong>
                  Is Journey Financial Services the right firm for you?
                </strong>
                <br />
              </h1>
              <p className="about-p">
                Our goal for this process is to demonstrate, in plain English,
                how to pursue your financial goals. This process is designed for
                managing your money, in effort to pay less in taxes and avoiding
                icebergs.
                <br />
                <br />
                There is no charge for this process as we want you to see
                exactly how we seek to improve your retirement before you agree
                to pay us a penny. To be honest, we also want the chance to make
                sure you are a good fit for our firm before we agree to work
                with you, possibly for the rest of your life.
              </p>
              <div className="swish text-center">
                <img alt="swish" src={swish} />
              </div>
            </div>
          </div>
        </div>

        {/* <div className="service-section3">
          <div className="container-fluid">
            <div className="col-md-12">
              <div className="col-md-12">
                <div className="bg text-center">
                  <img alt="checkmark" src={checkmarkw} />
                </div>
                <h1 className="section-h text-center text-white">
                  Steps before working with us.
                </h1>
              </div>

              <div className="row">
                <div className="cPanelContent">
                  <div className="cPanelSection">
                    <div className="cPanelArrowPulse" />
                    <h5>Step 1</h5>
                    <div className="swish text-center">
                      <img alt="swishw" src={swishw} />
                    </div>
                    <h1 className="step-h text-white">
                      <strong>Initial Phone Meeting</strong>
                    </h1>
                    <p className="p">
                      Before committing your time or ours, this 15 minute phone
                      call will give us both a chance to make sure your
                      situation matches our experience. After all, you wouldn’t
                      see a Podiatrist if you needed heart surgery.
                      <br />
                      <br />
                      <em>
                        If we aren’t a good fit for each other we will gladly
                        introduce you to a firm who is better suited to your
                        needs.
                      </em>{' '}
                    </p>
                  </div>

                  <div className="cPanelSection">
                    <div className="cPanelArrowPulse" />
                    <h5>Step 2</h5>
                    <div className="swish text-center">
                      <img alt="swishw" src={swishw} />
                    </div>
                    <h1 className="step-h text-white">
                      <strong>First Office Visit</strong>
                    </h1>
                    <p className="p">
                      <strong>
                        Prescription without evaluation and diagnosis is
                        malpractice.
                      </strong>
                      <br />
                      The goal of this meeting is to get perfectly clear on your
                      goals, concerns and unique financial situation.
                      <br />
                      <br />
                      Your answers to our questions along with the documents we
                      requested you bring will guide our analysis which will
                      result in plain-English answers to four critical
                      questions.{' '}
                    </p>
                  </div>

                  <div className="cPanelSection">
                    <h5>Step 3</h5>
                    <div className="swish text-center">
                      <img alt="swishw" src={swishw} />
                    </div>
                    <h1 className="step-h text-white">
                      <strong>Our Analysis</strong>
                    </h1>
                    <p className="p">
                      <strong>This is where the magic happens.</strong>
                      <br />
                      We will apply decades of experience, thousands of hours of
                      training and mentoring by some of the industry’s top
                      professionals to answer the following questions:
                      <br />
                      <br />✓ Can you afford to retire?
                      <br />✓ Are you overpaying your taxes?
                      <br />✓ Can your portfolio be improved?
                      <br />✓ Can you eliminate, or at least reduce, threats to
                      your retirement?{' '}
                    </p>
                  </div>
                </div>
              </div>

              <br />
              <br />

              <div className="cPanelContent">
                <div className="cPanelSection">
                  <div className="cPanelArrowPulse" />
                  <h5>Step 4</h5>
                  <div className="swish text-center">
                    <img alt="swishw" src={swishw} />
                  </div>
                  <h1 className="step-h text-white">
                    <strong>Second Office Meeting</strong>
                  </h1>
                  <p className="p">
                    <strong>This is where the </strong>{' '}
                    <strong>proverbial rubber hits the road.</strong>
                    <br />
                    During this meeting we will explain, in plain English,
                    exactly what you need to do to pursue your financial goals,
                    including the answers to the four questions we asked during
                    our analysis.
                    <br />
                    <br />
                    This is where you will see, in dollars and cents, how our
                    firm can help you work toward improving your finances.
                    <br />
                    <br />
                    <em>
                      At the end of this meeting we will ask if you would like
                      to begin working with our firm or if you would like to
                      sleep on it.
                    </em>
                    <br />
                  </p>
                </div>
                <div className="cPanelSection">
                  <div className="cPanelArrowPulse" />
                  <h5>Step 5</h5>
                  <div className="swish text-center">
                    <img alt="swishw" src={swishw} />
                  </div>
                  <h1 className="step-h text-white">
                    <strong>Sleep on It</strong>
                  </h1>
                  <p className="p">
                    We are in no rush for you to make a decision. Prior to
                    deciding if we should work together you will want to answer
                    the following questions:
                    <br />
                    <br />✓ Do I like/trust/respect the team at Journey
                    Financial Services?
                    <br />✓ Will the benefits they provide exceed their cost?
                    <br />✓ Do I understand and agree with their investment
                    philosophy?
                    <br />✓ Do their recommendations make sense to me?
                    <br />
                  </p>
                </div>
                <div className="cPanelSection">
                  <h5>Step 6</h5>
                  <div className="swish text-center">
                    <img alt="swishw" src={swishw} />
                  </div>
                  <h1 className="step-h text-white">
                    <strong>Third Meeting/ Paperwork</strong>
                  </h1>
                  <p className="p">
                    During this meeting we will answer any questions you have
                    and if you are ready, start the paperwork to become a
                    client.
                    <br />
                    <br />
                    Prior to this meeting we will have sent you a detailed
                    explanation of this process and answered all of your
                    questions and concerns.
                    <br />
                    <br />
                    <em>
                      *While we would love to work with you, if you decide that
                      it’s not a good fit we will wish you all the best and
                      every success. In other words, there is never a hard-sell
                      or pressure to say yes.
                    </em>
                    <br />
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div> */}

        <div className="steps-to-develop pt-5">
          <div className="container">
            <div className="col-md-12">
              <div className="bg text-center">
                <img alt="checkmark" src={checkmark} />
              </div>
              <h1 className="section-h text-center">
                <strong>
                  Steps to Develop your Money Meaning Map- Full Comprehensive
                </strong>
                <br />
              </h1>

              <div className="row">
                <div className="col-md-6 mb-5">
                  <div className="justify-content-center">
                    <h2 className="pricing-header text-center">
                      <strong>Introduction Meeting</strong>
                    </h2>
                    <div className="swish text-center">
                      <img alt="swish" src={swish} />
                    </div>
                    <p className="about-p mt-3">
                      In this meeting we ask you to communicate your financial
                      goals, discuss your investing history and provide us with
                      an overview of your current financial situation. As a part
                      of this discovery process, we share our philosophy of
                      money called “Investment Blueprints”, and explain the
                      business model of Journey Financial Services with respect
                      to serving our clients.
                    </p>
                  </div>
                </div>
                <div className="col-md-6 mb-5">
                  <div className="justify-content-center">
                    <h2 className="pricing-header text-center">
                      <strong>
                        Commitment To Planning and Gathering of Data
                      </strong>
                    </h2>
                    <div className="swish text-center">
                      <img alt="swish" src={swish} />
                    </div>
                    <p className="about-p mt-3">
                      When a decision is made to begin working with our firm, we
                      will send you contract paperwork and list of documents
                      necessary to begin the financial planning process. Once
                      you collect those items, the documents are scanned into
                      our system and forwarded to our financial planning
                      department. Your documents are returned to you in the
                      exact order they were provided to us.
                    </p>
                  </div>
                </div>
                <div className="col-md-6 mb-5 mx-auto text-center">
                  <div className="justify-content-center">
                    <h2 className="pricing-header text-center">
                      <strong>Team Introduction</strong>
                    </h2>
                    <div className="swish text-center">
                      <img alt="swish" src={swish} />
                    </div>
                    <p className="about-p mt-3">
                      As we grow, a Service Select Team will be assigned to you.
                      This will include a Relationship Manger, whose primary
                      focus will be to “shepherd” you through the process and be
                      the liaison to our financial planning department. It will
                      also include a Lead Advisor who will work closely with you
                      to create and implement the steps of your financial plan.
                    </p>
                  </div>
                </div>
              </div>

              {/* <div className="swish text-center">
                <img alt="swish" src={swish} />
              </div> */}
            </div>
          </div>
        </div>

        <div className="service-section3">
          <div className="container-fluid overflow-hidden">
            <div className="col-md-12 ">
              <div className="bg text-center">
                <img alt="checkmark" src={checkmark} />
              </div>
              <h1 className="section-h text-white text-center mb-5">
                <strong>Phase One-Create your Money Meaning Map</strong>
                <br />
              </h1>

              <div className="row gx-3">
                <div
                  className="col-md-5 mb-5 px-lg-5 px-2"
                  style={{ backgroundColor: '#ffffff24' }}
                >
                  <div className="justify-content-center px-3 py-4">
                    <h2 className="step-h text-white">
                      <strong>Data Review Call/Meeting</strong>
                    </h2>
                    <div className="swish text-center">
                      <img alt="swishw" src={swishw} />
                    </div>
                    <p className="service-p mt-3 text-white">
                      Approximately two to three weeks after receipt of the
                      requested documents, after we have had a chance to input
                      your data, a conference call will be scheduled to discuss
                      the data you have provided and secure any additional
                      information necessary to accurately model your financial
                      situation
                    </p>
                  </div>
                </div>
                <div className="col-md-2 col-offset-2"></div>

                <div
                  className="col-md-5 mb-5 px-lg-5 px-2"
                  style={{ backgroundColor: '#ffffff24' }}
                >
                  <div className="justify-content-center px-3 py-4">
                    <h2 className="step-h text-white">
                      <strong>Visions and Values Conversation</strong>
                    </h2>
                    <div className="swish text-center">
                      <img alt="swishw" src={swishw} />
                    </div>
                    <p className="service-p mt-3 text-white">
                      A core element of our risk management and estate planning
                      process involves this in-depth conversation to uncover two
                      things: Potential “trainwrecks” that could upset your
                      ability to live out your preferred lifestyle and/or
                      “opportunities”, either in lifetime or at death that would
                      allow you to help the people or causes you care most
                      about.
                    </p>
                  </div>
                </div>
                <div
                  className="col-md-5 mb-5 px-lg-5 px-2"
                  style={{ backgroundColor: '#ffffff24' }}
                >
                  <div className="justify-content-center px-3 py-4">
                    <h2 className="step-h text-white">
                      <strong>Investment Collaboration Meeting</strong>
                    </h2>
                    <div className="swish text-center">
                      <img alt="swishw" src={swishw} />
                    </div>
                    <p className="service-p mt-3 text-white">
                      This meeting is an opportunity to work with our team to
                      learn more about the wide variety of investment vehicles
                      we have access to and participate in the design and
                      construction of your financial plan. Your informed
                      feedback will allow us to custom design an investment
                      blueprint that works for you and focuses on your
                      objectives.
                    </p>
                  </div>
                </div>
                <div className="col-md-2"></div>
                <div
                  className="col-md-5 mb-5 px-lg-5 px-2"
                  style={{ backgroundColor: '#ffffff24' }}
                >
                  <div className="justify-content-center px-3 py-4">
                    <h2 className="step-h text-white">
                      <strong>Presentation of Your Money Meaning Map</strong>
                    </h2>
                    <div className="swish text-center">
                      <img alt="swishw" src={swishw} />
                    </div>
                    <p className="service-p mt-3 text-white">
                      Utilizing the information gathered, your team will present
                      a fully customized “money meaning map” which will outline
                      steps to be taken to help you pursue your stated goals and
                      objectives. Specific investment recommendations and action
                      steps will be provided for your review and consideration.
                      All paperwork needed to effect your customized plan will
                      be provided at this meeting.
                    </p>
                  </div>
                </div>
                <div
                  className="col-md-6 mb-5 px-lg-5 px-2 mx-auto text-center"
                  style={{ backgroundColor: '#ffffff24' }}
                >
                  <div className="justify-content-center px-3 py-4">
                    <h2 className="step-h text-white">
                      <strong>Follow up meeting/Signing Party</strong>
                    </h2>
                    <div className="swish text-center">
                      <img alt="swishw" src={swishw} />
                    </div>
                    <p className="service-p mt-3 text-white">
                      Within 3 to 5 business days after the presentation of your
                      money meaning map, we have a meeting to answer questions
                      and/ or provide feedback on our investment
                      recommendations. Assuming the plan addresses your
                      financial objectives, we have a “signing Party” to execute
                      forms and celebrate your first steps towards your
                      financial independence!
                    </p>
                  </div>
                </div>
              </div>

              {/* <div className="swish text-center">
                <img alt="swish" src={swish} />
              </div> */}
            </div>
          </div>
        </div>

        <div className="steps-to-develop pt-5">
          <div className="container">
            <div className="col-md-12">
              <div className="bg text-center">
                <img alt="checkmark" src={checkmark} />
              </div>
              <h1 className="section-h text-center">
                <strong>
                  Phase Two-Risk Management/Estate Planning Process
                </strong>
                <br />
              </h1>

              <div className="row">
                <div className="col-md-5 mb-5">
                  <div className="justify-content-center">
                    <h2 className="pricing-header text-center">
                      <strong>Review of Risk Management Findings</strong>
                    </h2>
                    <div className="swish text-center">
                      <img alt="swish" src={swish} />
                    </div>
                    <p className="about-p mt-3">
                      We will review with you any risks identified in the
                      visions and values conversation and offer guidance and
                      suggestions as to how you can transfer risks identified.
                      Some risks (like health risks) cannot be transferred, but
                      instead must be assumed.
                    </p>
                  </div>
                </div>
                <div className="col-md-2"></div>
                <div className="col-md-5 mb-5">
                  <div className="justify-content-center">
                    <h2 className="pricing-header text-center">
                      <strong>Estate Plan Design Meeting</strong>
                    </h2>
                    <div className="swish text-center">
                      <img alt="swish" src={swish} />
                    </div>
                    <p className="about-p mt-3">
                      A review of your current documents (if applicable) will be
                      discussed. If new or revised documents are warranted,
                      design assistance will be provided, ensuring the documents
                      accurately reflect your goals.
                    </p>
                  </div>
                </div>

                <div className="col-md-5 mb-5">
                  <div className="justify-content-center">
                    <h2 className="pricing-header text-center">
                      <strong>Draft of Attorney Letter</strong>
                    </h2>
                    <div className="swish text-center">
                      <img alt="swish" src={swish} />
                    </div>
                    <p className="about-p mt-3">
                      We will draft an attorney letter on your behalf, outlining
                      the decisions made during Estate Plan Design Meeting. In
                      the letter, we ask the attorney to give a quote on the
                      project. If changes are needed, your team will work as a
                      liaison with drafting attorney and continue to review all
                      subsequent drafts.
                    </p>
                  </div>
                </div>
                <div className="col-md-2"></div>
                <div className="col-md-5 mb-5">
                  <div className="justify-content-center">
                    <h2 className="pricing-header text-center">
                      <strong>Estate Planning Document Execution</strong>
                    </h2>
                    <div className="swish text-center">
                      <img alt="swish" src={swish} />
                    </div>
                    <p className="about-p mt-3">
                      A member of your Service Team will assist with the
                      execution of your estate planning documents.
                    </p>
                  </div>
                </div>

                <div className="col-md-5 mb-5">
                  <div className="justify-content-center">
                    <h2 className="pricing-header text-center">
                      <strong>Titling and Beneficiary Review</strong>
                    </h2>
                    <div className="swish text-center">
                      <img alt="swish" src={swish} />
                    </div>
                    <p className="about-p mt-3">
                      Once your estate planning documents have been executed, we
                      will assist with the re-titling of any assets and/or
                      liabilities (as deemed necessary) and make specific
                      recommendations as to how your beneficiary designations
                      should be modified to conform with your estate plan.
                    </p>
                  </div>
                </div>
                <div className="col-md-2"></div>
                <div className="col-md-5 mb-5">
                  <div className="justify-content-center">
                    <h2 className="pricing-header text-center">
                      <strong>Investment Statement Review</strong>
                    </h2>
                    <div className="swish text-center">
                      <img alt="swish" src={swish} />
                    </div>
                    <p className="about-p mt-3">
                      As you receive new investments, we will walk you through
                      your statements, help you understand the relevant
                      information and answer any questions you may have.
                    </p>
                  </div>
                </div>

                <div className="col-md-5 mb-5">
                  <div className="justify-content-center">
                    <h2 className="pricing-header text-center">
                      <strong>Family Meeting</strong>
                    </h2>
                    <div className="swish text-center">
                      <img alt="swish" src={swish} />
                    </div>
                    <p className="about-p mt-3">
                      An opportunity for your heirs to have an understanding of
                      estate planning concepts- normally actual dollar amounts
                      are not discussed but instead concepts are reviewed. The
                      goal is to provide a few “rules of the road.”
                    </p>
                  </div>
                </div>
                <div className="col-md-2"></div>

                <div className="col-md-5 mb-5">
                  <div className="justify-content-center">
                    <h2 className="pricing-header text-center">
                      <strong>Let’s Get/Stay Organized!</strong>
                    </h2>
                    <div className="swish text-center">
                      <img alt="swish" src={swish} />
                    </div>
                    <p className="about-p mt-3">
                      A member of your Service Select Team will work with you to
                      organize all of your documents and statements by using the
                      planning binder on the private client vault. They will
                      also train you on our robust online platform in an effort
                      to provide additional organizational structure and give
                      you access to real-time data via one online resource.
                    </p>
                  </div>
                </div>
              </div>

              {/* <div className="swish text-center">
                <img alt="swish" src={swish} />
              </div> */}
            </div>
          </div>
        </div>

        {/*
<div className="service-section3">
    <div className="container">
        <div className="col-md-12">
         
           <div className="bg text-center"><img src={checkmarkw} /></div>
    <h1 className="section-h text-center text-white">Steps before working with us.</h1>
        </div>
        <div className="row">
            <div className="col-md-4 serv-table">
                <h1 className="service-h text-white"><strong>Step 1</strong></h1>
                <h1 className="small-h text-white"><strong>Initial Phone Meeting</strong></h1>
                <div className="swish text-center"><img src={swishw} /></div>
                <p className="service-p text-white">Before committing your time or ours, this 15 minute phone call will give us both a chance to make sure your situation matches our expertise. After all, you wouldn’t see a Podiatrist if you needed heart surgery.<br /><br /><em>If we aren’t a good fit for each other we will gladly introduce you to a firm who is better suited to your needs.</em> </p>
            </div>
            <div className="col-md-4 serv-table">
                <h1 className="service-h text-white"><strong>Step 2</strong></h1>
                <h1 className="small-h text-white"><strong>First Office Visit</strong></h1>
                <div className="swish text-center"><img src={swishw} /></div>
                <p className="service-p text-white"><strong>Prescription without evaluation and diagnosis is malpractice.</strong><br />The goal of this meeting is to get perfectly clear on your goals, concerns and unique financial situation.<br /><br />Your answers to our questions along
                    with the documents we requested you bring will guide our analysis which will result in plain-English answers to four critical questions. </p>
            </div>
            <div className="col-md-4 serv-table">
                <h1 className="service-h text-white"><strong>Step 3</strong></h1>
                <h1 className="small-h text-white"><strong>Our Analysis</strong></h1>
                <div className="swish text-center"><img src={swishw} /></div>
                <p className="service-p text-white"><strong>This is where the magic happens.</strong><br />We will apply decades of experience, thousands of hours of training and mentoring by some of the industry’s top professionals to answer the following questions:<br /><br />✓ Can you
                    afford to retire?<br />✓ Are you overpaying your taxes?<br />✓ Can your portfolio be improved?<br />✓ Can you eliminate, or at least reduce, threats to your retirement? </p>
            </div>
        </div>
    </div>
</div>
<div className="journey-steps">
    
    <div className="container-fluid">
        
        <div className="row">
            <div className="col-md-4 serv-table">
                <h1 className="service-h text-white"><strong>Step 4</strong></h1>
                <h1 className="small-h text-white"><strong>Second Office Meeting</strong></h1>
                <div className="swish text-center"><img src={swishw} /></div>
                <p className="service-p text-white"><strong>This is where the </strong> <strong>proverbial rubber hits the road.</strong><br />During this meeting we will explain, in plain English, exactly what you need to do to pursue your financial goals, including the answers to
                    the four questions we asked during our analysis.<br /><br />This is where you will see, in dollars and cents, how our firm can help you work toward improving your finances.<br /><br /><em>At the end of this meeting we will ask if you would like to begin working with our firm or if you would like to sleep on it.</em><br /></p>
            </div>
            <div className="col-md-4 serv-table">
                <h1 className="service-h text-white"><strong>Step 5</strong></h1>
                <h1 className="small-h text-white"><strong>Sleep on It</strong></h1>
                <div className="swish text-center"><img src={swishw} /></div>
                <p className="service-p text-white">Having been in business for more than 7 years, we are in no rush for you to make a decision. Prior to deciding if we should work together you will want to answer the following questions:<br /><br />✓ Do I like/trust/respect the team at
                    Journey Financial Services?<br />✓ Will the benefits they provide exceed their cost?<br />✓ Do I understand and agree with their investment philosophy?<br />✓ Do their recommendations make sense to me?<br /></p>
            </div>
            <div className="col-md-4 serv-table">
                <h1 className="service-h text-white"><strong>Step 6</strong></h1>
                <h1 className="small-h text-white"><strong>Third Meeting &amp; Paperwork</strong></h1>
                <div className="swish text-center"><img src={swishw} /></div>
                <p className="service-p text-white">During this meeting we will answer any questions you have and if you are ready, start the paperwork to become a client.<br /><br />Prior to this meeting we will have sent you a detailed explanation of this process and answered all of your questions and concerns.<br /><br /><em>*While we would love to work with you, if you decide that it’s not a good fit we will wish you all the best and every success. In other words, there is never a hard-sell or pressure to say yes.</em><br
                    /></p>
            </div>
        </div>
    </div>
</div>
*/}
        <div id="newsletter" className="cont">
          <div className="container">
            <div className="col-md-12">
              <div className="bg text-center">
                <img alt="moneydialogue" src={moneydialogue} />
              </div>
              <h1 className="section-h text-center text-white">
                <strong>
                  Get advice from Journey Financial Services&nbsp;
                </strong>
              </h1>
              <p className="service-p text-white">
                <em>
                  You will automatically be subscribed to our quarterly
                  newsletter&nbsp;to receive e-mails&nbsp;filled with articles,
                  advice and financial guidance
                </em>
              </p>
            </div>
          </div>
          <div className="container">
            <SampleForm
              form_name="Newsletter Form"
              form_id="5df865b7676f4567eacd2532"
              form_classname="form-newsletter"
              className="row justify-content-center"
              method="post"
            >
              <div className="form-row">
                <div className="mb-3">
                  <div className="form-group">
                    <input
                      className="form-control c-form"
                      type="text"
                      name="Name"
                      id="full_name"
                    />
                    <label htmlFor="full_name">Enter Your Name</label>
                  </div>
                </div>
                <div className="mb-3">
                  <div className="form-group">
                    <input
                      className="form-control c-form"
                      type="email"
                      name="Email Address"
                      id="email_address"
                    />
                    <label htmlFor="email_address">Email</label>
                  </div>
                  <small className="form-text text-danger">
                    Please enter a correct email address.
                  </small>
                </div>
                <div className="mb-3">
                  <div className="form-group">
                    <input
                      className="form-control c-form"
                      type="text"
                      name="MailingAddress"
                      id="mailing_address"
                    />
                    <label htmlFor="mailing_address">Mailing Address</label>
                  </div>
                </div>
                <div className="mb-3">
                  <div className="form-group">
                    <input
                      className="form-control c-form"
                      type="text"
                      name="Phone"
                      id="phone"
                    />
                    <label htmlFor="phone">Phone</label>
                  </div>
                </div>
                <div className="mb-3">
                  <div className="form-group">
                    <button
                      className="btn btn-primary bttn float-left c-button"
                      type="submit"
                    >
                      Subscribe
                    </button>
                  </div>
                </div>
                <div className="mb-3">
                  <div className="form-group">
                    <div className="webriq-recaptcha" />
                  </div>
                </div>
              </div>
            </SampleForm>
          </div>
        </div>
        {/*  <SampleForm
            form_name="Newsletter Form"
            form_id="5bcd42f86b63453b251972bc"
            form_classname="form-newsletter"
          >
          <div className="form-row">
          <div className="col-12 mb-3">
            <div className="form-group">
              <input
                className="form-control"
                type="email"
                name="Email Address"
                id="email_address"
              />
              <label htmlFor="email_address">
                Enter Your Email Address
              </label>
            </div>
            <small className="text-dark form-text mt-2">
                We will never share your email with anyone else.
              </small>
          </div>
          <div className="col-12 mb-3">
            <div className="form-group">
              <input
                className="form-control"
                type="text"
                name="Name"
                id="full_name"
              />
              <label htmlFor="full_name">
                Enter Your Name
              </label>
            </div>
          </div>
          <div className="col-12">
            
              <div className="webriq-recaptcha" />
            
          </div>

          <div className="col-12">
            <div className="form-group">
              <button
                className="btn btn-primary hvr-shadow"
                type="submit"
              >
                Submit
              </button>
            </div>
          </div>
        </div>
          </SampleForm>
        */}
      </Layout>
    )
  }
}
export default OurProcessPage

export const OurProcessPageQuery = graphql`
  query OurProcessPageQuery {
    site {
      siteMetadata {
        title
        author
        description
      }
    }
  }
`
